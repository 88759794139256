<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <!-- Left side for participant -->
        <div
          v-if="isParticipantPortal"
          class="
            w-100
            d-lg-flex
            align-items-center
            justify-content-center
            flex-column
            px-5
          "
        >
          <b-img
            fluid
            :src="appLogo"
            alt="ART of Mentoring"
          />
          <h1
            class="program-name primary-color mb-0 text-truncate"
            style="white-space: break-spaces;"
          >
            {{ program.name }}
          </h1>
        </div>

        <div
          v-else
          class="
            w-100
            d-lg-flex
            align-items-center
            justify-content-center
            px-5
          "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="ART of Mentoring"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <transition mode="out-in">
          <b-col
            v-if="isInitialState"
            key="1"
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div
              class="
                  w-100
                  d-flex
                  align-items-center
                  justify-content-center
                  px-5
                  is-displayed
                "
            >
              <b-img
                v-if="isParticipantPortal"
                fluid
                :src="appLogo"
                alt="ART of Mentoring"
                class="mb-5"
                style="vertical-align: text-top;"
              />

              <b-img
                v-else
                fluid
                :src="imgUrl"
                alt="ART of Mentoring"
                class="mb-5"
                style="vertical-align: text-top;"
              />
            </div>
            <b-alert
              v-if="showRegister"
              show
              variant="danger"
            >
              <div class="alert-body"><span class="font-weight-normal">You don't have any roles in this program. Would you like to register? </span>
                <router-link
                  class="text-danger font-weight-bolder"
                  :to="{ name: 'participant-register', params: { clientSlug:$route.params.clientSlug, programPath: $route.params.programPath } }"
                >
                  Click here to register
                </router-link>
              </div>
            </b-alert>
            <!-- Form title -->
            <h3 class="text-center">
              {{ pageTitle }}
            </h3>

            <b-alert
              v-if="didSessionTimeout"
              variant="warning"
              show
            >
              <div class="alert-body">
                <feather-icon
                  size="18"
                  class="mr-1"
                  icon="AlertTriangleIcon"
                />
                Your session has timed out. Please log in again to
                continue.
              </div>
            </b-alert>
            <b-alert
              v-if="didParticipantRegister"
              variant="danger"
              show
            >
              <div class="alert-body">
                <span class="font-weight-normal">You have registered successfully. Please check your email for a verification link and click the '</span><span class="font-weight-bolder">Complete registration</span><span class="font-weight-normal">' button to verify your email before logging in</span>
              </div>
            </b-alert>
            <b-alert
              v-if="didParticipantVerify"
              variant="success"
              show
            >
              <div class="alert-body">
                <feather-icon
                  size="18"
                  class="mr-1"
                  icon="CheckIcon"
                />
                Email Verification successful. Please login now.
              </div>
            </b-alert>


            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{ invalid, pristine }"
            >
              <!-- SSO is enabled -->
              <div v-if="isSamlSsoEnabled">
                <!-- @todo saml btn -->
                <b-button
                  type="button"
                  variant="primary"
                  block
                  :href="samlSsoHref"
                >
                  {{ samlSsoBtnText }}
                </b-button>

                <div class="d-flex justify-content-center align-items-center mt-1">
                  <hr class="w-100 divider">
                  <p class="font-weight-bolder m-0 px-2">
                    OR
                  </p>
                  <hr class="w-100 divider">
                </div>
                
                <div class="text-center">
                  <feather-icon
                    v-if="!showCollapseIconWithSSO"
                    v-b-toggle.collapse-login
                    icon="ArrowDownCircleIcon"
                    size="25"
                    @click="showCollapseIconWithSSO = true"
                  />

                  <feather-icon
                    v-else
                    v-b-toggle.collapse-login
                    icon="ArrowUpCircleIcon"
                    size="25"
                    @click="showCollapseIconWithSSO = false"
                  />
                </div>
                <b-collapse
                  id="collapse-login"
                  class="mt-2"
                >
                  <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent="login"
                  >
                    <!-- email -->
                    <b-form-group
                      label="Email"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        ref="email"
                        name="Email"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="userEmail"
                          :state="
                            errors.length > 0 ? false : null
                          "
                          name="login-email"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{
                          errors[0]
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">Password</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        ref="password"
                        name="Password"
                        vid="password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="
                            errors.length > 0
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <b-form-input
                            id="login-password"
                            v-model="password"
                            :state="
                              errors.length > 0 ? false : null
                            "
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="Password"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="
                                togglePasswordVisibility
                              "
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          errors[0]
                        }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      :disabled="pristine || invalid || isLoading"
                    >
                      <b-spinner
                        v-if="isLoading"
                        small
                      />
                      Sign in
                    </b-button>
                  </b-form>

                  <b-card-text class="text-center mt-2">
                    <b-link
                      :to="forGotPasswordRoute"
                    >
                      <small>Forgot Password?</small>
                    </b-link>
                  </b-card-text>
                </b-collapse>
              </div>
              
              <!-- Default login form -->
              <b-form
                v-else
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="email"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="
                        errors.length > 0 ? false : null
                      "
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    ref="password"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="
                        errors.length > 0
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="
                          errors.length > 0 ? false : null
                        "
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="
                            togglePasswordVisibility
                          "
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="pristine || invalid || isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Sign in
                </b-button>
                
                <!-- For Got Password -->
                <b-card-text class="text-center mt-2">
                  <b-link
                    :to="forGotPasswordRoute"
                  >
                    <small>Forgot Password?</small>
                  </b-link>
                </b-card-text>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <small>
                By clicking on "Sign in" you agree with Art of Mentoring
                platform's
                <router-link
                  :to="{ name: isParticipantPortal ? 'participant-terms' : 'app-terms' }"
                  target="_blank"
                ><u>Terms of Acceptable Use</u></router-link>
                and
                <router-link
                  :to="{ name: isParticipantPortal ? 'participant-privacy' : 'app-privacy' }"
                  target="_blank"
                ><u>Privacy Policy</u></router-link>
              </small>
            </b-card-text>
          </b-col>
          <b-col
            v-else-if="isSendTfaCodeState"
            key="2"
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Art of Mentoring!
            </b-card-title>
            <b-card-text class="mb-2">
              <p>Two-factor authentication required.</p>
              <p>
                Please select a method to receive your two-factor authentication
                token. You will need to enter this token correctly on the
                following screen in order to proceed.
              </p>
            </b-card-text>

            <!-- form -->
            <b-form
              class="mt-2"
              @submit.prevent="sendTfaCode"
            >
              <b-form-group>
                <b-form-radio-group
                  id="tfa-method-radio"
                  v-model="selectedTfaMethod"
                  :options="tfaMethods"
                  stacked
                />
              </b-form-group>
              <input
                type="hidden"
                name="email"
                :value="userEmail"
              >
              <input
                type="hidden"
                name="password"
                :value="password"
              >
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Send Code
              </b-button>
            </b-form>
          </b-col>
          <b-col
            v-else
            key="3"
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              Art of Mentoring!
            </b-card-title>
            <b-card-text class="mb-2">
              <p>Two-factor authentication token sent.</p>
              <p>
                Please enter the 6-digit token below to
                proceed.
              </p>
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="loginWithTfaCodeForm"
              #default="{ invalid, pristine, handleSubmit }"
            >
              <b-form
                class="mt-2"
                @submit.prevent="handleSubmit(loginWithTfaCode)"
              >
                <input
                  type="hidden"
                  name="email"
                  :value="userEmail"
                >
                <input
                  type="hidden"
                  name="password"
                  :value="password"
                >
                <!-- email -->
                <b-form-group
                  label="Token"
                  label-for="tfa-code"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="code"
                    name="Code"
                    vid="code"
                    rules="required|length:6"
                  >
                    <b-form-input
                      id="tfa-code"
                      v-model="code"
                      :state="
                        errors.length > 0 ? false : null
                      "
                      name="code"
                    />
                    <small class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="pristine || invalid || isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </transition>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
  BFormRadioGroup,
  BCollapse,
  VBToggle
} from "bootstrap-vue";
import authService from "@/services/authService";
//eslint-disable-next-line
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser, clearLocalStorage, setUserData } from "@/auth/utils";
import {
  makeErrorToast,
  isValidUserRole,
  getHighestUserRole,
  getUserRoles,
  getProgramsFromUserRoles,
  parseAbilitiesFromPermissions,
  getCookieValue
} from "@/libs/utils";
import { userRoles as userRolesModel, userStatus } from '@/models';
import { mapGetters } from 'vuex';
import { $themeConfig } from '@themeConfig';

const LOGIN_STATE_INITIAL = 0;
const LOGIN_STATE_SEND_TFA_CODE = 1;
const LOGIN_STATE_ENTER_TFA_CODE = 2;

export default {
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BFormRadioGroup,
    BCollapse
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showRegister: false,
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/logo/logo-h.png"),
      isLoading: false,
      loginState: 0,
      code: "",
      appLogoImage: $themeConfig.app.appLogoImage,
      tfaMethods: [],
      selectedTfaMethod: null,
      showCollapseIconWithSSO: false
    };
  },
  computed: {
    ...mapGetters('app',['currentProgram']),
    ...mapGetters("app", ["isParticipantPortal"]),

    pageTitle() {
      if (this.isParticipantPortal) {
        return "Participant Access";
      }
      return "Champion Access";
    },
    program () {
      return this.$store.getters['app/currentProgram'];
    },
    samlSsoBtnText() {
      //@todo make configurable
      return 'Login with SSO';
    },
    samlSsoHref() {
      //@todo expand to allow champ sso?
      const program = this.$store.state.app.currentProgram;
      return program ? `/saml/sso/${program.id}` : '/saml/sso';
    },
    isSamlSsoEnabled() {
      const program = this.$store.state.app.currentProgram;
      return program !== null && program.program_saml.is_enabled;
    },
    didSessionTimeout() {
      return this.$route.query.timeout == 1;
    },
    didParticipantRegister() {
      return this.$route.query.register === 'participant';
    },
    didParticipantVerify() {
      return Boolean(this.$route.query.verify) === true;
    },
    isInitialState() {
      return this.loginState === LOGIN_STATE_INITIAL;
    },
    isSendTfaCodeState() {
      return this.loginState === LOGIN_STATE_SEND_TFA_CODE;
    },
    isEnterTfaCodeState() {
      return this.loginState === LOGIN_STATE_ENTER_TFA_CODE;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/logo/logo-h.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
    forGotPasswordRoute () {
      return !this.isParticipantPortal 
        ? { name: 'auth-forgot-password' } 
        : { name: 'participant-forgot-password', params: { programPath: this.$route.params.programPath, programId: this.currentProgram?.id }};
    },
    appLogo() {
      let logo = this.appLogoImage;
      if (this.program && this.program.program_logos) {
        const primaryLogo = this.program.program_logos.find(logo => logo.type_id === 1);

        if (primaryLogo) {
          logo = primaryLogo.url;
        }
      }
      return logo;
    },
  },
  async created() {
    const token = this.$route.query.token;
    if (token && this.currentProgram) {
      try {
        const response = await authService.loginAsUser(this.currentProgram.id, token);
        this.onLoginSuccess(response.data);
      }catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      }
    }

    const ssoErrMsg = this.$route.query.sso_error_message;
    const isSso = this.$route.query.sso;

    if (ssoErrMsg) {
      this.$toast(makeErrorToast(ssoErrMsg));
    } else if (isSso) {
      try {
        const response = await authService.getUserProfile();
        this.onLoginSuccess(response.data);
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast(e));
      }
    }
  },
  methods: {
    async loginWithTfaCode() {
      this.isLoading = true;
      try {
        const response = await authService.loginWithTfaCode(
          this.userEmail,
          this.password,
          this.code
        );
        this.onLoginSuccess(response.data);
      } catch (e) {
        const { status, data } = e.response;
        if (status === 422 && data.errors) {
          this.$refs.loginWithTfaCodeForm.setErrors(data.errors);
        } else if (status === 404) {
          this.$toast(makeErrorToast(data.message));
        } else if (status === 500) {
          this.$log.error(e);
          this.$toast(makeErrorToast(data));
        }
      }
      this.isLoading = false;
    },
    async sendTfaCode() {
      this.isLoading = true;
      try {
        await authService.sendTfaCode(
          this.userEmail,
          this.password,
          this.selectedTfaMethod
        );
        this.loginState = LOGIN_STATE_ENTER_TFA_CODE;
      } catch (e) {
        const { status, data } = e.response;
        if (status === 502 || status === 400) {
          this.$toast(makeErrorToast(data.message));
        } else if (status === 500) {
          this.$log.error(e);
          this.$toast(makeErrorToast(data));
        }
      }
      this.isLoading = false;
    },
    async login() {
      this.showRegister = false;
      this.isLoading = true;
      try {
        const success = await this.$refs.loginForm.validate();
        if (!success) {
          this.isLoading = false;
          return;
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        return;
      }

      try {
        const response = await authService.login(
          this.userEmail,
          this.password,
          this.program? {program_id: this.program.id}: {}
        );
        this.isLoading = false;
        if (response?.status && response.status === 202) {
          //needs TFA
          this.loginState = LOGIN_STATE_SEND_TFA_CODE;
          this.tfaMethods = response.data.methods.map(m => ({text: m.message, value: m.method}));
          this.selectedTfaMethod = response.data.methods[0].method;
          return;
        }
        this.onLoginSuccess(response.data);
      } catch (e) {
        console.log(e);
        if(e?.response) {
          const { status, data } = e.response;
          if (status === 404 || status === 429 || status === 409) {
            this.$toast(makeErrorToast(data.message));
            return;
          } else if (status === 422 && data.errors) {
            this.$refs.loginForm.setErrors(data.errors);
            return;
          }
        }
        this.$toast(makeErrorToast('Something went wrong. Please clear your browser cache and try again.'));
        
      } finally {
        this.isLoading = false;
      }
    },
    async onLoginSuccess(user) {
      const userRoles = user.user_roles;
      const activeRoles = userRoles.filter(r => r.status_id === userStatus.ACTIVE);
      user.role = getHighestUserRole(activeRoles);// Highest role
      if (!isValidUserRole(user.role)) {
        // Notify when user login not role
        return authService.logout()
          .catch(() => {
            //ignore
          })
          .finally(() => {
            clearLocalStorage();
            return this.$toast(
              makeErrorToast(
                "You don’t have any active roles assigned to your account. Please contact our support team."
              )
            );
          });
      }
      user.roles = getUserRoles(userRoles);// Array of role names
      user.programs = getProgramsFromUserRoles(userRoles);
      // Is participant allowed to login to this program
      if(this.currentProgram && this.currentProgram.id) {
        if(!user.programs.includes(this.currentProgram.id)) {
          this.showRegister = true;
        }
      }
      // Parse abilities from user permission
      user.ability = parseAbilitiesFromPermissions({
        id: user.id,
        roles: user.roles,
        permissions: []
      });
      await this.fetchUsersProgramList();
      if ((user.role === userRolesModel.MENTOR || user.role === userRolesModel.MENTEE) && this.$route.params.programPath === undefined) {
        this.$toast(makeErrorToast('Sorry, we couldn\'t find your account. Check the URL and try again.'));
        this.$router.redirectBack(); 
      }
      setUserData({id:user?.id, roles: user.roles});
      this.$store.commit('profile/SET_USER_PROFILE', user);
      this.$ability.update(user.ability);
      const to = this.$route.query.next ? {path: this.$route.query.next } : getHomeRouteForLoggedInUser(user.role);
      if (this.$route.query.next && this.$route.query.next.includes('oauth/authorize')) {
        window.location.href = this.$route.query.next;
        return;
      }

      if (Number(user.role) === Number(userRolesModel.ADMIN)) {
        this.$store.dispatch('app/RELOAD_APP_DATA');
      }

      // Add program id to champion home route
      if (Number(user.role) === Number(userRolesModel.CHAMPION) || user.role === userRolesModel.PROGRAM_ADMIN) {
        const LAST_PROGRAM_ID = 'lastViewProgram';
        const userLastProgramId = getCookieValue(LAST_PROGRAM_ID);
        let programs = this.$store.getters['programs/programList'];
        if (!programs.length) {
          clearLocalStorage();
          this.loginState = LOGIN_STATE_INITIAL;
          this.isLoading = false;
          authService.logout()
          .catch(() => {
            //ignore
          });
          return this.$toast(
            makeErrorToast(
              "Your program has expired or is inactive, so you do not have access to this program."
            )
          );
        }
        if( userLastProgramId !== null && programs.filter(program => Number(program.id) === Number(userLastProgramId)).length > 0) {
          to.params = { id: userLastProgramId };;
        } else {
          to.params = { id: programs[0].id };
        }
      }


      // Add programPath for participant
      if (Number(user.role) === Number(userRolesModel.MENTOR) || Number(user.role) === Number(userRolesModel.MENTEE) || Number(user.role) === Number(userRoles.PARTICIPANT_CANDIDATE)) {
        to.params = { programPath: this.$route.params.programPath };
      }

      return this.$router.replace(to);
    },
    async fetchUsersProgramList() {
      await this.$store.dispatch('programs/GET_PROGRAMS');  
    }
  },
};
</script>

<style lang="scss">
.img-fluid {
  width: 50%;
  max-width: 50%;
  height: auto;
  @media only screen and (max-width: 991px) {
    max-width: 80%;
  }
}

#tfa-method-radio > div {
  padding-bottom: 10px;
}

@media screen and (min-width: 993px) {
  .is-displayed {
    display: none !important;
  }
}

@import "@/assets/scss/vue/pages/page-auth.scss";
</style> 